import * as React from 'react';
import ReactMapboxGl, { Marker } from 'react-mapbox-gl';
import styled from 'styled-components';
import Dropdown from './dropdown';
import DrawControl from 'react-mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import elementResizeEvent from 'element-resize-event';

const { token, styles } = require('./config.json');
const geocodingUrl = 'https://api.mapbox.com/geocoding/v5';
const mapboxGeocoding = (query) =>
    `${geocodingUrl}/mapbox.places/${query}.json?access_token=${token}`;

const Container = styled.div`
  position: relative;
  height: 100%;
  flex: 1;
`;

const Mark = styled.div`
  background-color: #e74c3c;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 4px solid #eaa29b;
`;

const Map = ReactMapboxGl({ accessToken: token, trackResize: true });

const mapStyle = {
    height: "calc(100vh - 130px)",
};

const req = (url, body, method = 'GET') =>
    new Request(url, {
        method,
        headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Accept-Charset': 'utf-8'
        }),
        body
    });



class HtmlFeatures extends React.Component {
    state = {
        query: '',
        options: [],
        selected: undefined,
        center: [-111.9216064, 40.3398558, ],
    };

    fetch = (query) => {
        fetch(req(mapboxGeocoding(query)))
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    options: data.features
                        .filter((place) => place.place_type.includes('poi'))
                        .map((poi) => ({
                            id: poi.id,
                            center: poi.center,
                            name: poi.text
                        }))
                });
            });
    };

    onSelectItem = (index) => {
        const selected = this.state.options[index];
        this.setState({
            selected,
            center: selected.center
        });
    };

    onSearch = (query) => {
        this.setState({ query });
        this.fetch(query);
    };

    onStyleLoad = (map) => {
        const { onStyleLoad } = this.props;
        map.resize();
        return onStyleLoad && onStyleLoad(map);
    };

    render() {
        const { options, selected, center } = this.state;
        return (
            <Container>
                <Dropdown
                    onSearch={this.onSearch}
                    onSelectItem={this.onSelectItem}
                    options={options}
                />
                <Map
                    style={styles.satstreets}
                    containerStyle={mapStyle}
                    center={center}
                    onStyleLoad={this.onStyleLoad}
                    onViewportChange={nextViewport =>
                        this.setState({ viewport: { ...nextViewport, width: "fit", height: "fit" } })
                    }
                >
                    {selected && (
                        <Marker coordinates={selected.center}>
                            <Mark />
                        </Marker>
                    )}
                    <DrawControl/>
                </Map>
            </Container>
        );
    }
}

export default HtmlFeatures;
