import Icon from '@ant-design/icons';
import React from "react";

const LayersSvg = () => (
    <svg width="1em" height="1em" fill="currentColor"  viewBox="0 0 212 200" style={{'fillRule': 'evenodd', 'clipRule': 'evenodd', 'strokeLinejoin': 'round', 'strokeMiterlimit': 2}}>
        <path d="M211.157,93.65L179.567,76.964L211.121,56.369L105.58,0.136L0.036,56.367L31.59,76.962L0,93.651L31.59,114.271L0,130.955L105.579,199.864L211.159,130.955L179.567,114.269L211.159,93.65L211.157,93.65ZM186.75,57.455L165.808,71.124L147.486,59.712L167.064,46.969L186.75,57.455ZM94.203,79.587L76.238,91.283L55.779,77.927L74.264,66.608L94.203,79.587ZM85.988,59.428L104.927,47.83L124.377,59.944L105.579,72.182L85.988,59.428ZM105.579,86.992L123.557,98.696L105.579,110.431L87.6,98.696L105.579,86.992ZM116.955,79.585L136.005,67.183L154.342,78.605L134.921,91.279L116.954,79.585L116.955,79.585ZM154.552,40.298L135.858,52.468L116.767,40.577L134.588,29.661L154.552,40.298ZM105.579,14.207L121.878,22.889L104.982,33.236L88.788,23.152L105.579,14.207ZM76.132,29.896L93.14,40.489L74.466,51.927L56.607,40.299L76.132,29.896ZM24.408,57.453L44.094,46.965L62.742,59.106L44.271,70.417L24.408,57.453ZM24.445,94.783L43.484,84.726L105.579,125.255L167.674,84.726L186.712,94.783L105.579,147.736L24.446,94.783L24.445,94.783ZM186.712,132.089L105.579,185.041L24.446,132.088L43.486,122.031L105.58,162.56L167.676,122.031L186.714,132.088L186.712,132.089Z" style={{'fillRule': 'nonzero'}}/>
    </svg>
);

export function LayersIcon(props) {
    return (
        <Icon component={LayersSvg} {...props} />
    );
}
