import React from "react";
import { PieChart } from "@opd/g2plot-react";
import { measureTextWidth } from '@antv/g2plot';
import PropTypes from "prop-types";
import { LayersIcon } from "../icons/LayersIcon";
import {Typography} from "antd";
const {Text} = Typography;

/*
Not Rated
(20.30%)
4-6 %
(22.20%)
7-10 %
(21.80%)
11-15 %
(8.40%)
16-30 %
(6.10%)
31-70 %
(21.20%)
 */
const SoilPieChart = (props) => {
    const data = [
        {
            label: "Not Rated",
            value: 20.30,
        },
        {
            label: "4-6 %",
            value: 22.20,
        },
        {
            label: "7-10 %",
            value: 21.80,
        },
        {
            label: "11-15 %",
            value: 8.40,
        },
        {
            label: "16-30 %",
            value: 6.1,
        },
        {
            label: "31-70 %",
            value: 21.20,
        },
    ];

    const renderStatistic = (containerWidth, text, style) => {
        const {width: textWidth, height: textHeight} = measureTextWidth(text, style);
        const R = containerWidth / 2;
        // r^2 = (w / 2)^2 + (h - offsetY)^2
        let scale = 1;
        if (containerWidth < textWidth) {
            scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
        }
        const textStyleStr = `width:${containerWidth}px;`;
        //const convertedValue = (parseFloat(text)*props.totalAcreage/100).toFixed(2);
        const convertedValue = text;
        return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${convertedValue}</div>`;
    }

    return (
        <>
            <LayersIcon/> <Text strong>Topography Type</Text>
            <PieChart
                appendPadding={10}
                data={data}
                angleField={'value'}
                colorField={'label'}
                radius={1}
                innerRadius={0.64}
                meta={{
                    value: {
                        formatter: (v) => `${v} %`,
                    },
                }}
                label={{
                    type: 'outer',
                    autoRotate: true,
                    content: '{value}%',
                }}
                statistic={{
                    title: {
                        offsetY: -4,
                        customHtml: (container, view, datum) => {
                            const {width, height} = container.getBoundingClientRect();
                            const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
                            const text = datum ? "Acres " + datum.label : 'Total Acres';
                            return renderStatistic(d, text, {fontSize: 28});
                        },
                    },
                    content: {
                        offsetY: 4,
                        style: {
                            fontSize: '32px',
                        },
                        customHtml: (container, view, datum, data) => {
                            const {width} = container.getBoundingClientRect();
                            const totalAcreage = props.totalAcreage;
                            const value = datum ? `${datum.value}` : `${data.reduce((r, d) => r + d.value, 0)}`;
                            const text = (value*totalAcreage/100).toFixed(2) + ' ' + '(' + parseFloat(value).toFixed(1) + '%)';
   //                         const text = datum ? `${(datum.value*totalAcreage/100).toFixed(2)}` : `${(data.reduce((r, d) => r + d.value, 0)*totalAcreage/100).toFixed(2)}`;
                            return renderStatistic(width, text, {fontSize: 28});
                        },
                    },
                }}
                interactions={[{type: 'element-selected'}, {type: 'element-active'}, {type: 'pie-statistic-active'}]}
            />
        </>);
}

SoilPieChart.propTypes = {
    totalAcreage: PropTypes.number.isRequired,
};

export default SoilPieChart;
