import Icon from '@ant-design/icons';
import React from "react";

const CoinsGrowSvg = () => (
    <svg width="1em" height="1em" fill="currentColor"  viewBox="0 0 200 200" style={{'fillRule': 'evenodd', 'clipRule': 'evenodd', 'strokeLinejoin': 'round', 'strokeMiterlimit': 2}}>
        <path d="M149.886,188.281C126.228,188.281 107.698,178.33 107.698,165.625C107.698,163.911 109.109,162.5 110.823,162.5C112.538,162.5 113.948,163.911 113.948,165.625C113.948,173.386 128.706,182.031 149.886,182.031C171.066,182.031 185.823,173.386 185.823,165.625C185.823,163.911 187.234,162.5 188.948,162.5C190.663,162.5 192.073,163.911 192.073,165.625C192.073,178.33 173.544,188.281 149.886,188.281Z" style={{'fillRule': 'nonzero'}}/>
        <path d="M60.823,188.281C37.166,188.281 18.636,178.33 18.636,165.625C18.636,163.911 20.047,162.5 21.761,162.5C23.475,162.5 24.886,163.911 24.886,165.625C24.886,173.386 39.644,182.031 60.823,182.031C82.003,182.031 96.761,173.386 96.761,165.625C96.761,163.911 98.172,162.5 99.886,162.5C101.6,162.5 103.011,163.911 103.011,165.625C103.011,178.33 84.481,188.281 60.823,188.281Z" style={{'fillRule': 'nonzero'}}/>
        <path d="M60.823,171.094C37.166,171.094 18.636,161.142 18.636,148.438C18.636,146.723 20.047,145.313 21.761,145.313C23.475,145.313 24.886,146.723 24.886,148.438C24.886,156.198 39.644,164.844 60.823,164.844C82.003,164.844 96.761,156.198 96.761,148.438C96.761,146.723 98.172,145.313 99.886,145.313C101.6,145.313 103.011,146.723 103.011,148.438C103.011,161.142 84.481,171.094 60.823,171.094ZM149.886,171.094C126.228,171.094 107.698,161.142 107.698,148.438C107.698,135.733 126.228,125.781 149.886,125.781C173.544,125.781 192.073,135.733 192.073,148.438C192.073,161.142 173.544,171.094 149.886,171.094ZM149.886,132.031C128.706,132.031 113.948,140.677 113.948,148.438C113.948,156.198 128.706,164.844 149.886,164.844C171.066,164.844 185.823,156.198 185.823,148.438C185.823,140.677 171.066,132.031 149.886,132.031Z" style={{'fillRule': 'nonzero'}}/>
        <path d="M60.823,152.344C37.166,152.344 18.636,142.392 18.636,129.688C18.636,127.973 20.047,126.563 21.761,126.563C23.475,126.563 24.886,127.973 24.886,129.688C24.886,137.448 39.644,146.094 60.823,146.094C82.003,146.094 96.761,137.448 96.761,129.688C96.761,127.973 98.172,126.563 99.886,126.563C101.6,126.563 103.011,127.973 103.011,129.688C103.011,142.392 84.481,152.344 60.823,152.344ZM150.742,191.406C125.8,191.406 106.992,180.995 106.992,167.188C106.992,164.214 107.905,161.297 109.661,158.594C107.905,155.891 106.992,152.973 106.992,150C106.992,136.192 125.8,125.781 150.742,125.781C175.684,125.781 194.492,136.192 194.492,150C194.492,152.973 193.58,155.891 191.823,158.594C193.58,161.297 194.492,164.214 194.492,167.188C194.492,180.995 175.684,191.406 150.742,191.406ZM150.742,135.156C131.067,135.156 116.367,142.994 116.367,150C116.367,151.739 117.255,153.527 119.005,155.309C120.784,157.121 120.784,160.065 119.005,161.877C117.255,163.661 116.367,165.447 116.367,167.186C116.367,174.192 131.067,182.03 150.742,182.03C170.417,182.03 185.117,174.192 185.117,167.186C185.117,165.447 184.23,163.659 182.48,161.877C180.701,160.065 180.701,157.121 182.48,155.309C184.23,153.525 185.117,151.739 185.117,150C185.117,142.994 170.417,135.156 150.742,135.156Z" style={{'fillRule': 'nonzero'}}/>
        <path d="M60.823,135.156C37.166,135.156 18.636,125.205 18.636,112.5C18.636,99.795 37.166,89.844 60.823,89.844C84.481,89.844 103.011,99.795 103.011,112.5C103.011,125.205 84.481,135.156 60.823,135.156ZM60.823,96.094C39.644,96.094 24.886,104.739 24.886,112.5C24.886,120.261 39.644,128.906 60.823,128.906C82.003,128.906 96.761,120.261 96.761,112.5C96.761,104.739 82.003,96.094 60.823,96.094Z" style={{'fillRule': 'nonzero'}}/>
        <path d="M61.68,191.406C36.738,191.406 17.93,180.995 17.93,167.188C17.93,164.214 18.842,161.297 20.598,158.594C18.842,155.891 17.93,152.973 17.93,150C17.93,146.722 19.023,143.541 21.131,140.625C19.023,137.709 17.93,134.528 17.93,131.25C17.93,128.277 18.842,125.359 20.598,122.656C18.842,119.953 17.93,117.036 17.93,114.063C17.93,100.255 36.738,89.844 61.68,89.844C86.622,89.844 105.43,100.255 105.43,114.063C105.43,117.036 104.517,119.953 102.761,122.656C104.517,125.359 105.43,128.277 105.43,131.25C105.43,134.528 104.336,137.709 102.228,140.625C104.336,143.541 105.43,146.722 105.43,150C105.43,152.973 104.517,155.891 102.761,158.594C104.517,161.297 105.43,164.214 105.43,167.188C105.43,180.995 86.622,191.406 61.68,191.406ZM61.68,99.219C42.005,99.219 27.305,107.056 27.305,114.063C27.305,115.802 28.192,117.589 29.942,119.372C31.721,121.183 31.721,124.128 29.942,125.939C28.192,127.723 27.305,129.509 27.305,131.248C27.305,133.197 28.402,135.188 30.566,137.159C31.539,138.047 32.095,139.306 32.095,140.623C32.095,141.941 31.539,143.2 30.566,144.087C28.402,146.061 27.305,148.05 27.305,149.998C27.305,151.737 28.192,153.525 29.942,155.308C31.721,157.119 31.721,160.064 29.942,161.875C28.192,163.659 27.305,165.445 27.305,167.184C27.305,174.191 42.005,182.028 61.68,182.028C81.355,182.028 96.055,174.191 96.055,167.184C96.055,165.445 95.167,163.658 93.417,161.875C91.638,160.064 91.638,157.119 93.417,155.308C95.167,153.523 96.055,151.737 96.055,149.998C96.055,148.05 94.958,146.059 92.794,144.087C91.82,143.199 91.265,141.941 91.265,140.623C91.265,139.306 91.82,138.047 92.794,137.159C94.958,135.186 96.055,133.197 96.055,131.248C96.055,129.509 95.167,127.722 93.417,125.939C91.638,124.128 91.638,121.183 93.417,119.372C95.167,117.588 96.055,115.802 96.055,114.063C96.055,107.056 81.355,99.219 61.68,99.219Z" style={{'fillRule': 'nonzero'}}/>
        <path d="M62.386,111.719C59.797,111.719 57.031,109.62 57.031,107.031L57.031,57.031C57.031,54.442 59.797,52.344 62.386,52.344C64.975,52.344 67.969,54.442 67.969,57.031L67.969,107.031C67.969,109.62 64.977,111.719 62.386,111.719Z" style={{'fillRule': 'nonzero'}}/>
        <path d="M66.559,85.822C65.761,85.822 65.323,85.802 65.323,85.802C62.909,85.692 60.954,83.736 60.844,81.322C60.802,80.33 59.956,56.861 74.366,42.448C86.933,29.883 106.353,28.906 112.006,28.906C112.803,28.906 113.239,28.927 113.239,28.927C115.653,29.037 117.609,30.992 117.719,33.406C117.761,34.398 118.608,57.867 104.197,72.28C91.631,84.845 72.211,85.822 66.559,85.822ZM108.208,38.438C101.63,38.933 89.106,40.967 80.995,49.078C72.861,57.214 70.831,69.623 70.347,76.294C76.919,75.8 89.453,73.769 97.57,65.652C105.661,57.559 107.706,45.122 108.208,38.438Z" style={{'fillRule': 'nonzero'}}/>
        <path d="M61.338,65.509C55.686,65.509 36.266,64.533 23.7,51.967C9.291,37.555 10.136,14.086 10.178,13.094C10.288,10.68 12.244,8.724 14.658,8.614C17.072,8.504 15.095,8.594 15.891,8.594C21.544,8.594 40.964,9.57 53.531,22.136C67.944,36.548 67.097,60.017 67.053,61.009C66.943,63.424 64.988,65.379 62.573,65.489C60.159,65.599 62.134,65.509 61.338,65.509ZM19.691,18.125C20.192,24.806 22.237,37.245 30.33,45.339C38.441,53.45 50.966,55.483 57.542,55.98C57.042,49.298 54.997,36.859 46.903,28.766C38.792,20.655 26.269,18.62 19.691,18.125Z" style={{'fillRule': 'nonzero'}}/>
    </svg>
);

export function CoinsGrowIcon(props) {
    return (
        <Icon component={CoinsGrowSvg} {...props} />
    );
}

