import React, {Component} from 'react';
import {ApolloProvider} from 'react-apollo';
import ApolloClient from 'apollo-boost';
import {
    Layout,
    Menu,
} from 'antd';
import './App.less';
import logo from './logo.svg';
import Proforma from "./components/Proforma";
import {DividerBox} from "rc-dock";
import "rc-dock/dist/rc-dock.css";
import Map from "./components/Map";

const {Header, Content, Footer, Sider} = Layout;
const GRAPHQL_API_URL = 'https://test.aeonai.com/ws/graphql';

const client1 = new ApolloClient({
    uri: GRAPHQL_API_URL,
});

let layoutLeft = {
    dockbox: {
        mode: 'vertical',
        children: [
            {
                tabs: [{id: 't1', title: 'Tool', content: <div>Left Side Dock Layout</div>}],
            },
            {
                tabs: [{id: 't2', title: 'Tool', content: <div>Left Side Dock Layout</div>}],
            }
        ]
    },
};


let layoutRight = {
    dockbox: {
        mode: 'horizontal',
        children: [
            {
                tabs: [{
                    id: 't3',
                    title: 'Dock',
                    content: <div>Right Side Dock Layout.</div>
                }],
            }
        ]
    },
};


class App extends Component {
    render() {
        return (
            <div className="App">
                <ApolloProvider client={client1}>
                    <Layout className="layout">
                        <Header>
                            <div className="logo">
                                <img height={35} src={logo} alt="logo"/>
                            </div>
                            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
                                <Menu.Item key="1">nav 1</Menu.Item>
                                <Menu.Item key="2">nav 2</Menu.Item>
                                <Menu.Item key="3">nav 3</Menu.Item>
                            </Menu>
                        </Header>
                        <Layout>
                            <Content>
                                <div className="site-layout-content">
                                    <DividerBox
                                        style={{position: 'relative', left: 10, top: 10, right: 10, bottom: 10}}>
                                        <DividerBox mode='vertical' style={{width: '40%', minWidth: 100}}>
                                            <div style={{border: '1px solid #ccc', padding: 5}}><Proforma/></div>
                                        </DividerBox>
                                        <DividerBox mode='vertical' style={{width: '40%', minWidth: 100}}>
                                            <div style={{border: '1px solid #ccc', padding: 10}}><Map/></div>
                                        </DividerBox>
                                    </DividerBox>
                                </div>
                            </Content>
                        </Layout>
                        <Footer style={{textAlign: 'center'}}>Copyright &copy; 2021 Aeon AI Inc.</Footer>
                    </Layout>
                </ApolloProvider>
            </div>
        );
    }
}

export default App;
