import React from 'react';
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';
import components from '@data-driven-forms/react-form-renderer/component-types';
import validators from "@data-driven-forms/react-form-renderer/validator-types";
import AntFormTemplate from '@data-driven-forms/ant-component-mapper/form-template';
import componentMapper from "@data-driven-forms/ant-component-mapper/component-mapper";
import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';
import FormGroup from '@data-driven-forms/ant-component-mapper/form-group';
import {CoinsGrowIcon} from "../icons/CoinsGrowIcon";
import { Input, Typography } from 'antd';
const { Text } = Typography;

const options = [
  {
    label: 'Cat',
    value: 'cat'
  },
  {
    label: 'Dog',
    value: 'dog'
  },
  {
    label: 'Duck',
    value: 'duck'
  },
  {
    label: 'Lion',
    value: 'lion'
  },
  {
    label: 'Monster',
    value: 'monster'
  }
];

const arraySchemaDDF = {
  title: 'FieldArray',
  fields: [
    {
      component: 'field-array',
      name: 'nicePeople',
      fieldKey: 'field_array',
      label: 'Nice people',
      description: 'This allow you to add nice people to the list dynamically',
      defaultItem: { name: 'enter a name', lastName: 'enter a last name' },
      fields: [
        {
          component: 'text-field',
          name: 'name',
          label: 'Name',
          placeholder: 'Borek',
          isRequired: true,
          validate: [
            {
              type: 'required'
            }
          ]
        },
        {
          component: 'text-field',
          name: 'lastName',
          label: 'Last Name',
          placeholder: 'Stavitel'
        }
      ]
    },
    {
      component: 'field-array',
      name: 'minItems',
      label: 'A list with a minimal number of items',
      validate: [{ type: 'min-items', threshold: 3 }],
      fields: [
        {
          component: 'text-field',
          label: 'Item'
        }
      ]
    },
    {
      component: 'field-array',
      name: 'number',
      defaultItem: 5,
      label: 'Default value with initialValues set',
      fields: [
        {
          component: 'text-field',
          label: 'Item',
          type: 'number'
        }
      ]
    },
    {
      component: 'field-array',
      name: 'minMax',
      minItems: 4,
      maxItems: 6,
      label: 'Min 4 item, max 6 items without validators',
      fields: [
        {
          component: 'text-field',
          isRequired: true,
          validate: [
            {
              type: 'required'
            }
          ]
        }
      ]
    }
  ]
};

const schema = {
  description: <><CoinsGrowIcon/><strong>Taxation</strong></>,
  fields: [
    {
      fields: [
        {
          title: 'Tab 1',
          description: 'Text boxes and text areas',
          name: '553',
          fields: [
            {
              title: 'Text boxes',
              name: '637',
              fields: [
                {
                  name: 'switch_1',
                  onText: 'Switch is on',
                  offText: 'Switch is off',
                  title: 'Switch',
                  component: components.SWITCH
                },
                {
                  name: 'switch_2',
                  label: 'Switch disabled',
                  component: components.SWITCH,
                  isDisabled: true
                },
                {
                  name: 'switch_3',
                  label: 'Switch readOnly',
                  component: components.SWITCH,
                  isReadOnly: true
                },
                {
                  name: 'text_box_2',
                  label: 'Text Box with help',
                  title: 'Text Box with help',
                  helperText: 'Helper text',
                  component: components.TEXT_FIELD
                },
                {
                  name: 'text_box_3',
                  label: 'Text Box required',
                  title: 'Text Box required',
                  isRequired: true,
                  component: components.TEXT_FIELD,
                  validate: [{ type: validators.REQUIRED }]
                },
                {
                  name: 'text_box_4',
                  label: 'Text Box readonly',
                  title: 'Text Box readonly',
                  isReadOnly: true,
                  component: components.TEXT_FIELD
                },
                {
                  name: 'text_box_5',
                  label: 'Text Box default',
                  title: 'Text Box default',
                  component: components.TEXT_FIELD
                },
                {
                  name: 'text_box_6',
                  label: 'Text Box unvisible',
                  title: 'Text Box unvisible',
                  component: components.TEXT_FIELD
                },
                {
                  name: 'text_box_7',
                  label: 'Text Box with validator',
                  title: 'Text Box with validator',
                  validate: [
                    {
                      type: validators.PATTERN,
                      pattern: '[0-9]'
                    }
                  ],
                  component: components.TEXT_FIELD
                },
                {
                  name: 'text_box_8',
                  label: 'Text Box integer value',
                  title: 'Text Box integer value',
                  dataType: 'integer',
                  component: components.TEXT_FIELD,
                  type: 'number'
                },
                {
                  name: 'text_box_9',
                  label: 'Text Box string value',
                  title: 'Text Box string value',
                  dataType: 'string',
                  component: components.TEXT_FIELD
                }
              ],
              component: components.SUB_FORM
            },
            {
              title: 'Text areas',
              name: '638',
              fields: [
                {
                  name: 'textarea_box_1',
                  label: 'Text Area',
                  title: 'Text Area',
                  component: components.TEXTAREA
                }
              ],
              component: components.SUB_FORM
            }
          ],
          component: components.TAB_ITEM
        },
        {
          title: 'Tab 2',
          description: 'Checks',
          name: '554',
          fields: [
            {
              title: 'Check boxes',
              name: '639',
              fields: [
                {
                  name: 'check_box_1',
                  label: 'Check Box',
                  title: 'Check Box',
                  component: components.CHECKBOX,
                  options: [
                    {
                      value: 1,
                      label: 'Option 1'
                    },
                    {
                      value: 2,
                      label: 'Option 2'
                    }
                  ]
                },
                {
                  name: 'check_box_2',
                  label: 'Check Box checked',
                  title: 'Check Box checked',
                  component: components.CHECKBOX
                }
              ],
              component: components.SUB_FORM
            },
            {
              title: 'Radios',
              name: '640',
              fields: [
                {
                  name: 'radio_button_1',
                  label: 'Radio Button',
                  title: 'Radio Button',
                  dataType: 'string',
                  component: components.RADIO,
                  options: [
                    {
                      label: 'One',
                      value: '1'
                    },
                    {
                      label: 'Two',
                      value: '2'
                    },
                    {
                      label: 'Three',
                      value: '3'
                    }
                  ]
                },
                {
                  name: 'radio_button_2',
                  label: 'Radio Button sorted by',
                  title: 'Radio Button sorted by',
                  dataType: 'string',
                  component: components.RADIO,
                  options: [
                    {
                      label: 'One',
                      value: '1'
                    },
                    {
                      label: 'Two',
                      value: '2'
                    },
                    {
                      label: 'Three',
                      value: '3'
                    }
                  ]
                },
                {
                  name: 'radio_button_4',
                  label: 'Radio Button default',
                  title: 'Radio Button default',
                  dataType: 'string',
                  component: components.RADIO,
                  options: [
                    {
                      label: 'One',
                      value: '1'
                    },
                    {
                      label: 'Two',
                      value: '2'
                    },
                    {
                      label: 'Three',
                      value: '3'
                    }
                  ]
                }
              ],
              component: components.SUB_FORM
            }
          ],
          component: components.TAB_ITEM
        },
        {
          title: 'Tab 3',
          description: '',
          name: '555',
          fields: [
            {
              title: 'Dropdowns',
              name: '641',
              fields: [
                {
                  name: 'dropdown_list_1',
                  label: 'Dropdown',
                  title: 'Dropdown',
                  dataType: 'string',
                  isSearchable: true,
                  isClearable: false,
                  isMulti: true,
                  component: components.SELECT,
                  options: [
                    {
                      // eslint-disable-next-line max-len
                      label:
                          'dropdown_list_1 dropdown_list_1 dropdown_list_1 dropdown_list_1 dropdown_list_1 dropdown_list_1 dropdown_list_1 dropdown_list_1 dropdown_list_1 dropdown_list_1 ',
                      value: 'foo'
                    },
                    {
                      label: 'One',
                      value: '1'
                    },
                    {
                      label: 'Three',
                      value: '3'
                    },
                    {
                      label: 'Two',
                      value: '2'
                    }
                  ]
                },
                {
                  name: 'dropdown_list_2',
                  label: 'Dropdown default value',
                  title: 'Dropdown default value',
                  dataType: 'string',
                  component: components.SELECT,
                  isClearable: true,
                  options: [
                    {
                      label: '<None>',
                      value: null
                    },
                    {
                      label: 'One',
                      value: '1'
                    },
                    {
                      label: 'Three',
                      value: '3'
                    },
                    {
                      label: 'Two',
                      value: '2'
                    }
                  ]
                },
                {
                  name: 'dropdown_list_3',
                  label: 'Dropdown multiselect',
                  title: 'Dropdown multiselect',
                  dataType: 'string',
                  component: components.SELECT,
                  isSearchable: true,
                  isMulti: true,
                  simpleValue: false,
                  options: [
                    {
                      label: '<None>',
                      value: undefined
                    },
                    {
                      label: 'One',
                      value: '1'
                    },
                    {
                      label: 'Three',
                      value: '3'
                    },
                    {
                      label: 'Two',
                      value: '2'
                    },
                    {
                      label: 'Four',
                      value: '4'
                    },
                    {
                      label: 'Five',
                      value: '5'
                    },
                    {
                      label: 'Six',
                      value: '6'
                    },
                    {
                      label: 'Seven',
                      value: '7'
                    },
                    {
                      label: 'Eight',
                      value: '8'
                    }
                  ]
                },
                {
                  name: 'dropdown_list_4',
                  label: 'Dropdown sort by value',
                  title: 'Dropdown sort by value',
                  dataType: 'string',
                  component: components.SELECT,
                  isSearchable: true,
                  options: [
                    {
                      label: '<None>',
                      value: null
                    },
                    {
                      label: 'One',
                      value: '1'
                    },
                    {
                      label: 'Two',
                      value: '2'
                    },
                    {
                      label: 'Three',
                      value: '3'
                    }
                  ]
                },
                {
                  name: 'dropdown_list_5',
                  label: 'Creatable multi select',
                  component: components.SELECT,
                  isMulti: true,
                  isClearable: true,
                  selectVariant: 'createable',
                  options: [
                    {
                      label: 'One',
                      value: '1'
                    },
                    {
                      label: 'Two',
                      value: '2'
                    },
                    {
                      label: 'Three',
                      value: '3'
                    }
                  ]
                }
              ],
              component: components.SUB_FORM
            }
          ],
          component: components.TAB_ITEM
        },
        {
          title: 'Tab 4',
          description: '',
          name: '556',
          fields: [
            {
              title: 'Datepickers',
              name: '642',
              fields: [
                {
                  name: 'date_control_1',
                  label: 'Datepicker',
                  title: 'Datepicker',
                  component: components.DATE_PICKER
                },
                {
                  name: 'date_control_2',
                  label: 'Datepicker with past days',
                  title: 'Datepicker with past days',
                  component: components.DATE_PICKER
                }
              ],
              component: components.SUB_FORM
            },
            {
              title: 'Timepickers',
              name: '643',
              fields: [
                {
                  name: 'date_time_control_1',
                  label: 'Timepicker',
                  title: 'Timepicker',
                  component: components.TIME_PICKER
                },
                {
                  name: 'date_time_control_2',
                  label: 'Timepicker with past days',
                  title: 'Timepicker with past days',
                  component: components.TIME_PICKER
                }
              ],
              component: components.SUB_FORM
            }
          ],
          component: components.TAB_ITEM
        },
        {
          title: 'Mixed',
          description: '',
          name: '558',
          fields: [
            {
              title: 'New Section',
              name: '645',
              fields: [
                {
                  name: 'text_box_10',
                  label: 'Text Box',
                  title: 'Text Box',
                  component: components.TEXT_FIELD
                },
                {
                  name: 'textarea_box_2',
                  label: 'Text Area',
                  title: 'Text Area',
                  component: components.TEXTAREA
                },
                {
                  name: 'check_box_3',
                  label: 'Check Box',
                  title: 'Check Box',
                  component: components.CHECKBOX
                },
                {
                  name: 'check_box_4',
                  label: 'Check Box',
                  title: 'Check Box',
                  component: components.CHECKBOX
                },
                {
                  name: 'dropdown_list_5',
                  label: 'Dropdown',
                  title: 'Dropdown',
                  dataType: 'string',
                  component: components.SELECT,
                  options: [
                    {
                      label: '<None>',
                      value: null
                    },
                    {
                      label: 'One',
                      value: '1'
                    },
                    {
                      label: 'Three',
                      value: '3'
                    },
                    {
                      label: 'Two',
                      value: '2'
                    }
                  ]
                },
                {
                  name: 'radio_button_3',
                  label: 'Radio Button',
                  title: 'Radio Button',
                  dataType: 'string',
                  component: components.RADIO,
                  options: [
                    {
                      label: 'One',
                      value: '1'
                    },
                    {
                      label: 'Two',
                      value: '2'
                    },
                    {
                      label: 'Three',
                      value: '3'
                    }
                  ]
                },
                {
                  name: 'date_time_control_3',
                  label: 'Timepicker',
                  title: 'Timepicker',
                  component: components.TIME_PICKER
                }
              ],
              component: components.SUB_FORM
            }
          ],
          component: components.TAB_ITEM
        },
        {
          title: 'Ant Exclusive',
          description: '',
          name: '559',
          fields: [
             {
              title: 'Cool Beans',
              name: '646',
              fields: [
                {
                  component: components.SLIDER,
                  name: 'simple-slider',
                  label: 'Slider'
                },
                {
                  component: components.SLIDER,
                  name: 'required-slider',
                  label: 'Slider required',
                  isRequired: true,
                  validate: [{ type: validators.REQUIRED }]
                },
                {
                  component: components.SLIDER,
                  name: 'range-custom-slider',
                  label: 'Slider custom range',
                  min: 10,
                  max: 132,
                  tooltipVisible: true
                },
                {
                  component: components.SLIDER,
                  name: 'range-slider',
                  label: 'Slider range',
                  range: true
                },
                {
                  component: components.SLIDER,
                  name: 'dost-slider',
                  label: 'Slider dots',
                  dots: true
                },
                {
                  component: components.SLIDER,
                  name: 'included-slider',
                  label: 'Slider included',
                  included: false
                },
                {
                  component: components.SLIDER,
                  name: 'marks-slider',
                  label: 'Slider marks',
                  marks: {
                    25: {
                      label: <h1>25 mark</h1>
                    }
                  }
                },
                {
                  component: components.SLIDER,
                  name: 'reverse-slider',
                  label: 'Slider reverse',
                  reverse: true
                },
                {
                  component: components.DUAL_LIST_SELECT,
                  name: 'default-transfer',
                  options
                },
                {
                  name: 'text_box_10',
                  label: 'Text Box',
                  title: 'Text Box',
                  component: components.TEXT_FIELD
                },
                {
                  name: 'textarea_box_2',
                  label: 'Text Area',
                  title: 'Text Area',
                  component: components.TEXTAREA
                },
                {
                  name: 'check_box_3',
                  label: 'Check Box',
                  title: 'Check Box',
                  component: components.CHECKBOX
                },
                {
                  name: 'check_box_4',
                  label: 'Check Box',
                  title: 'Check Box',
                  component: components.CHECKBOX
                },
                {
                  name: 'dropdown_list_5',
                  label: 'Dropdown',
                  title: 'Dropdown',
                  dataType: 'string',
                  component: components.SELECT,
                  options: [
                    {
                      label: '<None>',
                      value: null
                    },
                    {
                      label: 'One',
                      value: '1'
                    },
                    {
                      label: 'Three',
                      value: '3'
                    },
                    {
                      label: 'Two',
                      value: '2'
                    }
                  ]
                },
                {
                  name: 'radio_button_3',
                  label: 'Radio Button',
                  title: 'Radio Button',
                  dataType: 'string',
                  component: components.RADIO,
                  options: [
                    {
                      label: 'One',
                      value: '1'
                    },
                    {
                      label: 'Two',
                      value: '2'
                    },
                    {
                      label: 'Three',
                      value: '3'
                    }
                  ]
                },
                {
                  name: 'date_time_control_3',
                  label: 'Timepicker',
                  title: 'Timepicker',
                  component: components.TIME_PICKER
                }
              ],
              component: components.SUB_FORM
            }
          ],
          component: components.TAB_ITEM
        },
      ],
      component: components.TABS,
      name: '57'
    }
  ]
};

const defaultValues = {
  text_box_5: '"hello"',
  check_box_2: 'true',
  radio_button_4: '2',
  dropdown_list_2: '2'
};
/*
const schemaOrig = {
  fields: [
    {
      name: 'custom_email',
      label: 'Use custom email',
      component: componentTypes.CHECKBOX
    },
    {
      name: 'email',
      label: 'Email',
      component: componentTypes.TEXT_FIELD,
      resolveProps: (_props, _field, formOptions) => (formOptions.getState().values.custom_email ? { isDisabled: false } : { isDisabled: true })
    },
    {
      component: 'textbox-component',
      name: 'textbox-form',
      label: 'textbox-form',
      questionText: 'questionText',
      inputLabelAhead: 'inputLabelAhead',
      inputLabelBack: 'inputLabelBack',
      validate: [{type: 'required'}],
      validateOnMount: true
    }
  ]
};
*/
const TextboxComponent = ( props ) => {
  const {
    input,
    isReadOnly,
    isDisabled,
    placeholder,
    isRequired,
    label,
    helperText,
    description,
    validateOnMount,
    meta,
    FormItemProps,
    layout,
    questionText,
    questionNotice,
    inputLabelAhead,
    inputLabelBack,
    inputNotice,
    ...rest
  } = useFieldApi( props );

  return (
      <div className='wrapper'>
        <FormGroup
            label={label}
            meta={meta}
            validateOnMount={validateOnMount}
            helperText={helperText}
            description={description}
            FormItemProps={FormItemProps}
            isRequired={isRequired}
            layout={layout}
        >
          <div>
            <Text>{questionText}</Text>
          </div>
          <Text>{inputLabelAhead}</Text>
          <Input
              {...input}
              defaultValue={input.value ? input.value : undefined}
              disabled={isDisabled}
              readOnly={isReadOnly}
              placeholder={questionText}
              {...rest}
          />
          <Text>{inputLabelBack}</Text>
        </FormGroup>
      </div>
  );
};

/*
const componentMapper = {
  [ components.TEXT_FIELD ]: TextField,
  [ components.CHECKBOX]: Checkbox,
  'textbox-component': TextboxComponent
};*/

const FormTemplate = ( props ) => <AntFormTemplate {...props} showFormControls={true} layout='horizontal' />;

const TaxationForm = () => (
    <FormRenderer
        FormTemplate={FormTemplate}
        componentMapper={componentMapper}
        schema={schema}
        onSubmit={console.log}
        />
);

export default TaxationForm;
