import Icon from '@ant-design/icons';
import React from "react";

const CoinsSvg = () => (
    <svg width="1em" height="1em" fill="currentColor"  viewBox="0 0 200 200" style={{'fillRule': 'evenodd', 'clipRule': 'evenodd', 'strokeLinejoin': 'round', 'strokeMiterlimit': 2}}>
    <path d="M23.333,28.333C36.667,20.833 54.167,16.667 73.333,16.667C91.667,16.667 107.5,20.833 120.833,27.5C133.333,34.167 144.167,44.167 145.833,57.5C146.667,61.667 143.333,66.667 139.167,67.5C135,68.333 130,65 129.167,60.833C128.333,55 123.333,48.333 112.5,43.333C102.5,38.333 88.333,35 72.5,35C55.833,35 40.833,39.167 30.833,45C20.833,49.167 16.667,55.833 16.667,61.667C16.667,66.667 19.167,72.5 26.667,77.5C33.333,82.5 43.333,86.667 55.833,89.167C60,90 63.333,94.167 62.5,99.167C61.667,103.333 57.5,106.667 52.5,105.833C40,103.333 28.333,99.167 19.167,93.333C19.167,93.333 19.167,94.167 18.333,94.167C17.5,96.667 16.667,98.333 16.667,100.833C16.667,105.833 19.167,110.833 26.667,116.667C33.333,121.667 43.333,125.833 55.833,128.333C60,129.167 63.333,133.333 62.5,138.333C61.667,142.5 57.5,145.833 52.5,145C39.167,142.5 27.5,138.333 18.333,131.667C17.5,133.333 16.667,135.833 16.667,138.333C16.667,144.167 20.833,150 30,155.833C39.167,161.667 52.5,165.833 67.5,166.667C71.667,166.667 75,170.833 75,175C75,180 70.833,183.333 65.833,182.5C48.333,181.667 32.5,176.667 20.833,169.167C9.167,162.5 -0,151.667 -0,138.333C-0,132.5 1.667,127.5 4.167,122.5L6.667,120C2.5,114.167 0,108.333 0,100.833C0,95 1.667,90 4.167,85C5,83.333 5.833,82.5 7.5,81.667C2.5,76.667 0,70 0,61.667C0,47.5 10,35.833 23.333,28.333Z" style={{'fillRule': 'nonzero'}}/>
        <path d="M200,113.333C200,100.833 190.833,90.833 180,85C168.333,78.333 153.333,75 137.5,75C121.667,75 106.667,78.333 95,85C84.167,90.833 75,100.833 75,113.333C75,119.167 77.5,125 80.833,129.167C77.5,133.333 75,138.333 75,145C75,157.5 84.167,167.5 95,173.333C106.667,179.167 120.833,183.333 137.5,183.333C154.167,183.333 168.333,180 180,173.333C190.833,167.5 200,157.5 200,145C200,138.333 197.5,133.333 195,129.167C197.5,125 200,119.167 200,113.333ZM171.667,159.167C163.333,164.167 150.833,166.667 137.5,166.667C124.167,166.667 111.667,163.333 103.333,159.167C95,154.167 91.667,149.167 91.667,145C91.667,143.333 91.667,142.5 93.333,140.833C105,147.5 120.833,151.667 137.5,151.667C154.167,151.667 170,147.5 181.667,140.833C182.5,142.5 183.333,143.333 183.333,145C183.333,149.167 180,154.167 171.667,159.167ZM178.333,122.5C170.833,129.167 155.833,135 137.5,135C120,135 105.833,130 98.333,124.167L96.667,122.5C93.333,119.167 91.667,115.833 91.667,113.333C91.667,109.167 95,104.167 103.333,99.167C111.667,94.167 124.167,91.667 137.5,91.667C150.833,91.667 163.333,95 171.667,99.167C180,104.167 183.333,109.167 183.333,113.333C183.333,115.833 181.667,119.167 178.333,122.5Z" style={{'fillRule': 'nonzero'}}/>
    </svg>
);

export function CoinsIcon(props) {
    return (
        <Icon component={CoinsSvg} {...props} />
    );
}

